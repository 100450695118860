import React from 'react'
import { easytoimg,clock } from '../../assets/img'
import "./laundryService.css";

function LaundryService() {
  return (
    <div className="easelaundry-wrapper">
        
      <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="info-section aos-init" data-aos="zoom-in-up">
                  <div className="info-section-top">
                    <h2>Clean, Crisp & Fresh Laundry Delivered To Your Door.</h2>
                    <i className="fas fa-heart"></i>
                    <p>Rivers Laundry provides the finest Dry Cleaning & Laundry service in the London area combined with great customer service and convenient collection and delivery at times that suit you.</p>
                    <p> The service is simple – place an order in a matter of seconds, we will collect and clean your items, returning them washed and pressed less than 24 hours later. Our flexible collection and delivery slots, mean you don’t have to deal with restrictive opening times and you can place an order 24/7 online. Our on demand dry cleaning and laundry service covers the following post codes in the London area.</p>
                  </div>
                  <div className="info-section-body">
                    <img src={clock} alt=""/>
                    <h4>Rivers Laundry OPERATING HOURS</h4>
                    <p>Monday - Friday: 7am - 8.30pm</p>
                    <p>Saturday: 8am - 7pm</p>
                  </div>
                  <div className="info-section-body">
                    <h4>Rivers Laundry CONTACT</h4>
                    <p>info@riverslaundry.com, </p>
                    <p>Monday to Friday from 7am to 9pm</p>
                    <p>Saturday from 8am to 7pm.</p>
                  </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default LaundryService;