import React, { useEffect, useState } from 'react'
import "./orderOnline.css"
import axios from 'axios'
import { useNavigate } from 'react-router-dom';


function OrderOnline() {
    const areaCoveredData = {
        data:
        {
            "Response": {
                "response_code": "1",
                "response_message": "success"
            },
            "data": {
                "areas_covered": {

                    "R": [
                        "Rushmoor"
                    ]

                }

            }
        }
    }
    const base_url = process.env.REACT_APP_BACKEND_URL;
    const [areaCovered, setAreaCovered] = useState<any | null>(areaCoveredData)
    const navigate = useNavigate();

    useEffect(() => {

        const headers = {
            'Accept': 'application/json'
        }

        axios.get(`${base_url}/api/areas_covered`, {
            headers: headers

        }).then(e => { setAreaCovered({ data: e.data }) })

    }, [])

    const popAreadCovered=(areaList: any)=>
{

  
  
  let keys =Object.keys(areaList);
  let area = keys[0];
// let city =areaList.area;
// city=city.toString().toLowerCase();
//for (let j = arealistindex;j < arealistindex+3 ; j++) { 
  var test= Object.keys(areaList).map((location,locationindex)=>
  {
   //alert(areaList[location]);

   let cityArr = areaList[location].toString().split(',');
    
   return( <div className="city-list" id={'group_'+location}>
    <h3>{location}</h3>
    <ul>
{cityArr.map((city:any) =>  <li><a href='#' onClick={(e)=>{e.preventDefault(); navigate('/dry-cleaners/'+city)}}>{city}</a></li>)/*+city.toLowerCase()*/} 
    
     
    </ul>
</div>)  })

return test

}  
    return (
        <>
            <section className='location-section'>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading text-center aos-init mb-5" data-aos="zoom-in-up">
                                <h2>Selected Locations</h2>
                            </div>
                        </div>
                    </div>
                    <div className="location-wrapper aos-init" data-aos="zoom-in-up">
                        <div className="row">
                            <div className="alphabet-cont">
                            {/* <div className="col-md-4"> */}
                                {popAreadCovered(areaCovered.data.data.areas_covered)}
                                {/* <h3>West London Post Code Area</h3>
                                <ul>
                                    <li><a href=''>Acton</a></li>
                                    <li><a href=''>Boston Manor</a></li>
                                    <li><a href=''>East Acton</a></li>
                                    <li><a href=''>West Acton</a></li>
                                    <li><a href=''>Ealing</a></li>
                                    <li><a href=''>Hanwell</a></li>
                                    <li><a href=''>Park Royal</a></li>
                                </ul> */}
                            </div>
                            {/* </div> */}
                            {/* <div className="col-md-3">
                                <h3>West London Post Code Area</h3>
                                <ul>
                                    <li><a href=''>Acton</a></li>
                                    <li><a href=''>Boston Manor</a></li>
                                    <li><a href=''>East Acton</a></li>
                                    <li><a href=''>West Acton</a></li>
                                    <li><a href=''>Ealing</a></li>
                                    <li><a href=''>Hanwell</a></li>
                                    <li><a href=''>Park Royal</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <h3>West London Post Code Area</h3>
                                <ul>
                                    <li><a href=''>Acton</a></li>
                                    <li><a href=''>Boston Manor</a></li>
                                    <li><a href=''>East Acton</a></li>
                                    <li><a href=''>West Acton</a></li>
                                    <li><a href=''>Ealing</a></li>
                                    <li><a href=''>Hanwell</a></li>
                                    <li><a href=''>Park Royal</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <h3>West London Post Code Area</h3>
                                <ul>
                                    <li><a href=''>Acton</a></li>
                                    <li><a href=''>Boston Manor</a></li>
                                    <li><a href=''>East Acton</a></li>
                                    <li><a href=''>West Acton</a></li>
                                    <li><a href=''>Ealing</a></li>
                                    <li><a href=''>Hanwell</a></li>
                                    <li><a href=''>Park Royal</a></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
        // <div className="wash-fold-section">
        //         <div className="container-fluid">
        //             <div className="row justify-content-end" >
        //                 <div className="col-lg-8">
        //                     <div className="wash-fold-card">
        //                         <div className="wash-fold-text">
        //                             <h2>WASH <br />& <br/> FOLD</h2>
        //                         </div>
        //                         <div className="wash-fold-info">
        //                             <ul>
        //                                 <li><i className="fa fa-check"></i> <p>Washed under <span>30 degrees</span></p></li>
        //                                 <li><i className="fa fa-check"></i> <p>Dried at <span>medium heat</span></p></li>
        //                                 <li><i className="fa fa-check"></i> <p>Customer’s responsibility to <span>make sure all items are washable</span></p></li>
        //                                 <li><i className="fa fa-check"></i><span> <p><span>Towels and beddings</span> are  excluded and will be charged separately.</p></span></li>
        //                             </ul>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        // </div>
    )
}

export default OrderOnline