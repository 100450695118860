import React, { useEffect, useState } from "react";
import { NavLink,Link } from 'react-router-dom';
import { service1,service2,service3,service4,service5,service6,service7} from '../../assets/img'
import axios from "axios";
import { useSelector } from "react-redux";
function Ourservices() {
    const base_url = process.env.REACT_APP_BACKEND_URL;

    const priceListData={data:{"Response": {
        "response_code": "",
        "response_message": ""
    },"data": {
        "shop": {
            "id": "1",
            "owner": "",
            "name": "Xcel Dry Cleaners",
            "email": "xceldrycleaners@shop.com",
            "order_closing_time": ""
        },
        "minimum_order_amount": "20.00",
        "vat_percentage": "20.00",
        "daily_working_hours": "9",
        "price_currency": "\u00a3",
    "category": [{
            "main_category_id": "1",
            "main_category_name": "Men",
            "main_category_description": "",
            "selection": "f2faff",
            "background": "0d8ada",
            "andriod_banner_img": "",
            "ios_banner_img": "",
            "is_expandable": "0",
            "sub_categories": [{
                "sub_category_id": "1",
                "sub_category_name": "Silk Shirt",
                "process_time": "48",
                "quantity": "0",
                "andriod_sub_category_img": "",
                "ios_sub_category_img": "",
                "total_price": "5.00",
                "options": [{
                    "option_id": "1",
                    "option_name": "Washed, ironed and hung",
                    "option_description": "Washed, ironed and hung",
                    "option_price": "5.00",
                    "is_default": "1"
                }, {
                    "option_id": "2",
                    "option_name": "Washed, ironed and folded",
                    "option_description": "Washed, ironed and folded",
                    "option_price": "5.00",
                    "is_default": "0"
                }]
    }]}]
    
    }},
    rowcount:0,
    active:"active"
    }
    
    const [priceList, setPriceList] = useState< any | null>(priceListData)
    const [active, setActive] = useState(null)

    const settings: any = useSelector<any>(
        (state) => state.settings
      );

	useEffect(() => {
       
        const headers = {
            'Accept': 'application/json',
            "Access-Control-Allow-Origin": "*"
          }
          
              axios.get(`${base_url}/api/get_categories?group_id=1`,{
              headers: headers

}).then(e=>{setPriceList({data:e.data})



e.data.data.category.slice(0,1).map((category:any) => 
                                       
setActive(category.main_category_name)


)
})



    }, [])
  return (
    <div className="our-service-section">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-heading text-center aos-init mb-5" data-aos="zoom-in-up">
                        <h2>Our Pricing</h2>
                    </div>
                </div>
            </div>
            <div className="our-service-inner aos-init" data-aos="zoom-in-up">
                
                <div className="our-service-wrapper">
                    <div className="row">
                        {priceList.data.data.category.slice(0, 4).map((category:any,catindex:any) =>
                            <div className="col-md-3 d-flex col-sm-6">
                                <div className="service-widget flex-fill">
                                    <div className="services-img">
                                        <img src={category.web_banner_img} alt="" className="img-fluid" />
                                    </div>
                                    <h4>{category.main_category_name}</h4>
                                    <h3>Washed and Pressed</h3>
                                    <p>{category.main_category_description}</p>
                                </div>
                            </div>
                        )}
                        {/* <div className="col-md-4 d-flex col-sm-6">
                            <div className="service-widget flex-fill">
                                <div className="services-img">
                                    <img src={service2} alt="" className="img-fluid" />
                                </div>
                                <h4>Alterations</h4>
                                <p>Zip repairs, shortening, lengthening, tapering etc. FROM £4.00</p>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex col-sm-6">
                            <div className="service-widget flex-fill">
                                <div className="services-img">
                                    <img src={service3} alt="" className="img-fluid" />
                                </div>
                                <h4>Shirt Service</h4>
                                <p>Washed at 30°C or 45°C, Tumble dried, pressed and hanged or folded if requested. FROM £4.00</p>    
                            </div>
                        </div>
                        <div className="col-md-4 d-flex col-sm-6">
                            <div className="service-widget flex-fill">
                                <div className="services-img">
                                    <img src={service4} alt="" className="img-fluid" />
                                </div>
                                <h4>Ironing</h4>
                                <p>Items are delivered on hangers or folded if requested. FROM £3.00</p>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex col-sm-6">
                            <div className="service-widget flex-fill">
                                <div className="services-img">
                                    <img src={service5} alt="" className="img-fluid" />
                                </div>
                                <h4>Service Wash</h4>
                                <p>Washed at 30°C or 45°C, Tumble dried and Folded. FROM £13.00 per load of 3 kg</p>  
                            </div>
                        </div>
                        <div className="col-md-4 d-flex col-sm-6">
                            <div className="service-widget flex-fill">
                                <div className="services-img">
                                    <img src={service6} alt="" className="img-fluid" />
                                </div>
                                <h4>Beddings</h4>
                                <p>Washed dried and pressed. Larger items may require different cleaning method. FROM £3.00</p>
                            </div>
                        </div> */}
                    </div>
                    <div className="view-price">
                        <NavLink to={'./pricing'} className="btn btn-primary hover-btn">See Full Price List</NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Ourservices;
