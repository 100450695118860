import React, { useEffect, useState } from "react";
import { NavLink,Link } from 'react-router-dom';
import { howstep1,howstep2,howstep3,partner1,partner2,partner3} from '../../assets/img'
import QuickOrderPopup from "../quickOrderPopup";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
function Howitworks() {
    const [quickBookPopup, setQuickBookPopup] = useState(false);
  return (
    <>
    <div className="how-work-section">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-heading text-center aos-init mb-5" data-aos="zoom-in-up">
                        <h2>How it works ?</h2>
                    </div>
                </div>
            </div>
            <div className="how-work-inner aos-init" data-aos="zoom-in-up">
                
                <div className="how-work-wrapper">
                    <div className="work-item">
                        <div className="work-inner">
                            <div className="work-img">
                                <span>Step 1</span>
                                <img src={howstep1} alt="Laundry Pickup" />                                
                            </div>
                            <div className="work-content">
                                <p>Choose <strong>when</strong> and <strong>where</strong> you wish us to collect &amp; deliver your laundry.</p>
                            </div>
                        </div>
                    </div>
                    <div className="work-item">
                        <div className="work-inner">
                            <div className="work-img">
                                <span>Step 2</span>
                                <img src={howstep2} alt="Laundry Collection" />
                            </div>
                            <div className="work-content">
                                <p>We <strong>collect</strong> your bag, <strong>list</strong> and <strong>clean</strong> your items according to <strong>your requirements</strong>.</p>
                            </div>
                        </div>
                    </div>
                    <div className="work-item">
                        <div className="work-inner">
                            <div className="work-img">
                                <span>Step 3</span>
                                <img src={howstep3} alt="Laundry Delivery" />
                            </div>
                            <div className="work-content">
                                <p>We <strong>deliver</strong> your items cleaned within <strong>24 hours</strong> and at the time required.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-center" onClick={() => setQuickBookPopup(!quickBookPopup)}>
                        <Link to="#postcode-input" className="btn howitbtn hover-btn">Order now <i className="fas fa-caret-right ms-2"></i></Link>
                    </div>
                    <div className="modal fade" id="quickordermodal2" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                        <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">

                            {quickBookPopup && (
                            <QuickOrderPopup close={() => setQuickBookPopup(false)} />
                            // <QuickOrderPopup close={() => setQuickBookPopup(false)} />
                            )}

                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section className="partner-slider">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        breakpoints={{
                          640: {
                            slidesPerView: 1,
                          },
                          768: {
                            slidesPerView: 2,
                          },
                          1024: {
                            slidesPerView: 3,
                          },
                        }}
                        >
                        <SwiperSlide>
                            <img src={partner1} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={partner2} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={partner3} alt="" />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default Howitworks;
